// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-picsum-photo-list-js": () => import("/opt/build/repo/src/pages/PicsumPhotoList.js" /* webpackChunkName: "component---src-pages-picsum-photo-list-js" */),
  "component---src-pages-think-atomic-design-js": () => import("/opt/build/repo/src/pages/ThinkAtomicDesign.js" /* webpackChunkName: "component---src-pages-think-atomic-design-js" */)
}

